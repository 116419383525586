import { device } from 'src/styles/breakpoints'
import { brand } from 'src/styles/colors'
import styled from 'styled-components'

export const HowPortabilityWorksSection = styled.section`
  background-color: ${brand.claryOrange};

  h2 {
    font-size: 23px;
    line-height: 28px;

    @media ${device.tablet} {
      font-size: 30px;
      line-height: 36px;
    }

    @media ${device.desktopXL} {
      font-size: 40px;
      line-height: 48px;
    }
  }
`
