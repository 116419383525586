import React, { useState, useLayoutEffect } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import Button from '../../_componentes/_button/_index'
import Link from '../../_componentes/_link/_index'

import images, { IWebpImages } from 'src/pages/pra-voce/portabilidade-de-salario/assets/images/_images'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { ImgWebp } from 'src/styles/imgWebp'

import * as S from './style'

interface IHowPortabilityWorksProps {
  setIsOpenQRCode: Function;
}

export const HowPortabilityWorks = ({ setIsOpenQRCode }: IHowPortabilityWorksProps) => {
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const { howPortabilityWorks }: IWebpImages = images

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <S.HowPortabilityWorksSection className='default-padding d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-5 mb-4 mb-md-0'>
            <ImgWebp src={howPortabilityWorks.sm} alt='Mulher sorrindo com celular na mão' breakPointsSize={{ sm: '100%' }} />
          </div>
          <div className='col-12 col-md-7'>
            <h2 className='fw-700 text-white font-citrina text-center text-md-left'>Como funciona a Portabilidade de Salário?</h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 fw-400 text-white font-inter text-center text-md-left'>Com a Portabilidade de Salário, <span className='fw-600'>você tem liberdade para escolher em qual banco prefere receber o seu salário.</span> É totalmente digital, gratuito e um direito seu.</p>
            {
              isMobile ? (
                <Link
                  href='https://intergo.app/f650449c'
                  className='mb-3 mt-4'
                  text='Solicitar portabilidade'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      section_name: 'Como funciona a Portabilidade de Salário?',
                      element_action: 'click button',
                      element_name: 'Solicitar portabilidade',
                      redirect_url: 'https://intergo.app/f650449c',
                      })
                  }}
                />
                ) : (
                  <Button
                    onClick={() => {
                      setIsOpenQRCode(true)
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        section_name: 'Como funciona a Portabilidade de Salário?',
                        element_action: 'click button',
                        element_name: 'Solicitar portabilidade',
                      })
                    }}
                    text='Solicitar portabilidade'
                    className='mb-3 mt-4'
                  />
                )
              }
          </div>
        </div>
      </div>
    </S.HowPortabilityWorksSection>
  )
}

export default HowPortabilityWorks
