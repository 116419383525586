import styled from 'styled-components'
import { grayscale, green } from 'src/styles/colors'

export const PlatinumCardPointsPersonalizedServiceSection = styled.section`
  background-color: ${grayscale[100]};

  .cashback-color {
    color: ${green[400]}
  }
`
