import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { graphiteOne } from 'src/styles/newColors'
import styled from 'styled-components'

export const AdvantagesOfBringingYourSalaryToInterSection = styled.section`
  background-color: ${grayscale[100]};

  .advantage-card-container {
    @media ${device.tablet} {
      gap: 16px;
      justify-content: space-between;
    }
    display: flex;
    flex-wrap: wrap;
  }

  .advantage-card {
    background-color: white;
    padding: 21px 18px;
    border-radius: 8px;
    width: 100%;
    
    h3 {
      color: ${graphiteOne};
    }

    @media ${device.tablet} {
      width: 340px;
    }

    @media ${device.desktopLG} {
      width: 260px;
    }

    @media ${device.desktopXL} {
      width: 268px;
    }
  }
`
