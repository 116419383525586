import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { BlogCardT } from 'src/components/BlogCard/_types'
import BlogCard from 'src/components/BlogCard/_BlogCard'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { cardContent } from './assets/data/_cards'

import * as S from './style'

export const RelatedContent = () => {
  const [ sendDataLayer ] = useDataLayer()
  const data: BlogCardT[] = cardContent

  return (
    <S.RelatedContentSection className='default-padding d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-28 fs-md-30 lh-md-36 fs-xl-40 lh-xl-48 fw-700 text-grayscale--500 font-citrina text-md-center'>Conteúdos relacionados</h2>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 2, partialVisibilityGutter: 20 }}
              lg={{ items: 3 }}
              xl={{ items: 3 }}
            >
              {data.map((card: BlogCardT) => (
                <div key={card.id} className='mr-md-3'>
                  <BlogCard
                    card={card}
                    onCardClick={() => sendDataLayer({
                    section: 'dobra_07',
                    section_name: 'Conteúdos relacionados',
                    element_action: 'click button',
                    element_name: card.title,
                    redirect_url: card.link,
                  })}
                  />
                </div>
            ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.RelatedContentSection>
  )
}

export default RelatedContent
