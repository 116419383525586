export interface IWebpImages {
  [key: string]: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
}

const images: IWebpImages = {
  salaryPortabilityHero: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-hero/image.webp',
  },
  platinumCard: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/platinum-card-with-points-program-and-personalized-service/image.webp',
  },
  howPortabilityWorks: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-dobra2/image.webp',
  },
  stillDontHaveYourInterDigitalAccount: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-dobra5-360/image.webp',
    md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-dobra5-768/image.webp',
    lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-dobra5-1024/image.webp',
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-dobra5-1/image.webp',
  },
}

export default images
