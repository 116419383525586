import { BlogCardT } from "src/components/BlogCard/_types"

export const cardContent: BlogCardT[] = [
  {
    id: '1',
    category: 'Para simplificar a vida',
    title: 'Menor de idade pode investir?',
    date: '09/07/2020',
    link: 'https://blog.inter.co/menor-de-idade-pode-investir/',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-blog1/image.webp',
  },
  {
    id: '2',
    category: 'Cuidando do seu dinheiro',
    title: 'Posso fazer portabilidade de salário mesmo devendo ao banco?',
    date: '08/10/2019',
    link: 'https://blog.inter.co/posso-fazer-portabilidade-de-salario-mesmo-devendo-o-banco/',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-blog2/image.webp',
  },
  {
    id: '3',
    category: 'Cuidando do seu dinheiro',
    title: 'Veja os benefícios do Cartão Platinum e formas de obter o seu sem anuidade!',
    date: '11/01/2019',
    link: 'https://blog.inter.co/cartao-platinum/',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/portabilidade-blog3/image.webp',
  },
]
