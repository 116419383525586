import React from 'react'
import Button from '../../_componentes/_button/_index'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import * as S from './style'

type HeroProps = {
  setIsOpenAccount: Function;
  setDataLayer: Function;
}

export const StillDontHaveYourInterDigitalAccount = ({ setIsOpenAccount, setDataLayer }: HeroProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Ainda não tem sua Conta Digital Inter?',
    element_action: 'click button',
    element_name: 'Abrir conta digital',
  }

  return (
    <S.StillDontHaveYourInterDigitalAccountSection className='d-flex align-items-center'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-6 col-xl-7 content-background'>
            <h2 className='fs-24 lh-28 fs-md-30 lh-md-36 fs-xl-40 lh-xl-48 fw-700 text-grayscale--500 font-citrina text-center mb-3'>Ainda não tem sua Conta Digital Inter?</h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 fw-400 text-grayscale--400 font-inter text-center mb-4 pb-2'>Em poucos cliques você traz o seu salário pro Inter. É tudo no Super App e sem pagar nada. </p>
            <Button
              onClick={() => {
                setIsOpenAccount(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
              text='Abrir conta digital'
            />
          </div>
        </div>
      </div>
    </S.StillDontHaveYourInterDigitalAccountSection>
  )
}

export default StillDontHaveYourInterDigitalAccount
