import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Wrapper = styled.div`
  a, button {
    font-weight: 700;
  }

  .default-padding {
    padding: 40px 0;
    @media ${device.tablet} {
      padding: 65px 0;
    }
    @media ${device.desktopXL} {
      padding: 80px 0;
    }
  }
`
