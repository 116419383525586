/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react'
import QRCode from 'react-qr-code'
import ModalAccount from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { CloseButton, Container, Image } from './style'

import AppStorePT from 'inter-frontend-svgs/lib/v2/social/store/app-store'
import PlayStorePT from 'inter-frontend-svgs/lib/v2/social/store/google-play'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type ModalProps = {
  isModal: boolean;
  setIsModal: Function;
  title?: string;
  qrCode: string;
  instructions?: string;
  subtitle?: string;
  isPJ?: boolean;
  isHrTop?: boolean;
  isHrBottom?: boolean;
  isNewFont?: boolean;
  openAccount?: string;
  openAccountModal?: boolean;
  isStore?: boolean;
  className?: string;
  section?: string;
  sectionName?: string;
  dataLayer?: IDataLayerParams;
  isMinPadding: boolean;
  isWebm?: boolean;
}

export const QrCodeModal = ({
  isModal,
  setIsModal,
  title,
  qrCode,
  instructions,
  subtitle,
  isPJ = false,
  isHrTop,
  isHrBottom,
  isNewFont,
  openAccountModal,
  openAccount,
  isStore,
  className,
  section,
  sectionName,
  dataLayer,
  isMinPadding,
  isWebm,
}: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const [ open, setOpen ] = useState(false)

  const AppStoreUrl = 'https://itunes.apple.com/br/app/banco-inter/id839711154'
  const GooglePlayUrl = 'https://play.google.com/store/apps/details?id=br.com.intermedium'

  const openModal = domReady && (
    <ModalAccount
      closeModal={() => setIsModal(false)}
      dataLayer={dataLayer}
      section={section}
      sectionName={sectionName}
      customOrigin='COE'
    />
  )

  function renderQrCode (isWebm: boolean = false, qrCode: string) {
    if (isWebm) {
      return <Image src={qrCode} />
    }
    if (qrCode.includes('://')) {
      return <QRCode size={185} value={qrCode} />
    }
    return <Image src={qrCode} />
  }

  return (
    <Container isModal={isModal} isMinPadding={isMinPadding}>
      {open ? openModal
        : <div>
          <CloseButton onClick={() => {
            setIsModal(false)
          }}
          >
            <OrangeDsIcon icon='exit' size='MD' color={isPJ ? '#07605B' : '#ff7a00'} />
          </CloseButton>
          <div className={className}>
            { title &&
              <h3
                className={`fs-24 lh-30 fs-md-32 lh-md-40 text-grayscale--500 fw-600 mb-5 ${isNewFont ? 'font-citrina' : 'font-sora'}`}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            }
            { subtitle &&
              <>
                {isHrTop && <hr />}
                <p className='fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--400' dangerouslySetInnerHTML={{ __html: subtitle }} />
              </>
            }
            <div className='text-center mb-5'>
              {renderQrCode(isWebm, qrCode)}
            </div>
            { instructions &&
              <>
                {isHrBottom && <hr />}
                <p className='fs-14 lh-17 text-grayscale--400 fw-400 mb-4' dangerouslySetInnerHTML={{ __html: instructions }} />
              </>
            }
            { openAccount &&
              <div>
                <span className='fs-14 lh-17 mb-0 mr-1 fw-700' dangerouslySetInnerHTML={{ __html: openAccount }} />
                {openAccountModal &&
                  <span
                    className='fs-14 lh-17 text-orange--extra fw-700 cursor-pointer'
                    onClick={() => {
                      setOpen(true)
                      sendDatalayerEvent({
                        ...dataLayer,
                        section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                        section_name: dataLayer ? dataLayer.section_name : '',
                        element_name: 'Crie a sua conta agora!',
                        element_action: 'click button',
                      })
                    }}
                  >
                    Crie a sua conta agora!
                  </span>}
              </div>
            }
            { isStore && (
              <div className='row'>
                <div className='col-6 d-flex justify-content-end'>
                  <a
                    onClick={() => {
                        sendDatalayerEvent({
                          section: 'null',
                          element_action: 'click button',
                          element_name: 'App Store',
                          redirect_url: AppStoreUrl,
                          c_page: window.location.href,
                        })
                    }}
                    href={AppStoreUrl}
                    target='_blank' rel='noreferrer' title='Baixe na App Store' aria-label='App Store'
                    data-footer='download-app-store'
                  >
                    <AppStorePT width={130} height='45' />
                  </a>
                </div>
                <div className='col-6 d-flex justify-content-start'>
                  <a
                    onClick={() => {
                        sendDatalayerEvent({
                          section: 'null',
                          element_action: 'click button',
                          element_name: 'Google Play',
                          redirect_url: GooglePlayUrl,
                          c_page: window.location.href,
                        })
                    }}
                    href={GooglePlayUrl}
                    target='_blank' rel='noreferrer'
                    title='Baixe no Google Play' aria-label='Google Play' data-footer='download-google-play'
                  >
                    <PlayStorePT width={130} height='45' />
                  </a>
                </div>
              </div>
              )
            }
          </div>
        </div>
      }
    </Container>
  )
}

QrCodeModal.defaultProps = {
  isMinPadding: false,
}
