import React, { useState, useLayoutEffect } from 'react'

import useWidth from 'src/hooks/window/useWidth'

import { ImgWebp } from 'src/styles/imgWebp'
import images, { IWebpImages } from 'src/pages/pra-voce/portabilidade-de-salario/assets/images/_images'

import Button from '../../_componentes/_button/_index'
import Link from '../../_componentes/_link/_index'
import IconsSwitch from 'src/components/IconsSwitch/_index'

import * as S from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const WIDTH_MD = 768

type HeroProps = {
  setIsOpenAccount: Function;
  setIsOpenQRCode: Function;
  setDataLayer: Function;
}

const Hero = ({ setIsOpenAccount, setIsOpenQRCode, setDataLayer }: HeroProps) => {
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()
  const { salaryPortabilityHero }: IWebpImages = images

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
    element_action: 'click button',
    element_name: 'Abrir conta',
  }

  return (
    <>
      <S.HeroSection className='default-padding d-flex position-relative align-items-center'>
        <div className='container'>
          <div className='row d-flex align-items-center justify-content-between'>
            <div className='col-12 col-md-6 col-lg-4 col-xl-5 mb-5 mb-md-0 order-md-last text-md-right'>
              <ImgWebp src={salaryPortabilityHero.sm} alt='mulher de roupa laranja sorrindo com celular na mão' breakPointsSize={{ sm: '320px', md: '310px', lg: '310px', xl: '468px' }} />
            </div>
            <div className='col-12 col-md-6 col-lg-8 col-xl-7'>
              <div className='breadcrumb d-flex align-items-center mb-3 md-xl-2'>
                <IconsSwitch icon='ic_house' lib='interco' customPath='action-navigation/' size='LG' color='#616161' />
                <IconsSwitch icon='ic_arrow_right' lib='interco' customPath='action-navigation/' size='LG' color='#616161' className='mx-3' />
                <span>Portabilidade de Salário</span>
              </div>
              <h1 className='mb-4'>
                <span className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-orange--extra font-citrina fw-700 mb-2 d-block'>Portabilidade de Salário</span>
                <span className='fs-20 lh-24 fs-md-30 lh-md-36 fs-xl-32 lh-xl-40 text-grayscale--500 font-sora fw-600 mb-0 d-block'>Escolha cuidar do seu dinheiro em uma conta gratuita e segura</span>
              </h1>
              <p className='fs-14 lh-17 fs-md-18 lh-md-22'>
                Trazendo o seu salário, você concentra sua vida financeira em uma conta gratuita, pode fazer seu dinheiro render com diversos tipos de investimentos, aproveitar para usar muito mais o cartão de crédito e acumular pontos no Inter Loop.
              </p>

              {
                isMobile ? (
                  <Link
                    href='https://intergo.app/f650449c'
                    className='mb-3 mt-4'
                    text='Solicitar portabilidade'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
                        element_action: 'click button',
                        element_name: 'Solicitar portabilidade',
                        redirect_url: 'https://intergo.app/f650449c',
                       })
                    }}
                  />
                  ) : (
                    <Button
                      onClick={() => {
                        setIsOpenQRCode(true)
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
                          element_action: 'click button',
                          element_name: 'Solicitar portabilidade',
                        })
                      }}
                      text='Solicitar portabilidade'
                      className='mb-3 mt-4'
                    />
                )
              }
              <Button
                onClick={() => {
                  setIsOpenAccount(true)
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
                }}
                text='Abrir conta'
                reverse={true}
              />
            </div>
          </div>
        </div>
      </S.HeroSection>
    </>
  )
}

export default Hero
