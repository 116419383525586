import React from 'react'
import { orange } from 'src/styles/colors'
import IconsSwitch from 'src/components/IconsSwitch/_index'

import * as S from './style'

interface ICard {
  iconCategory: string;
  icon: string;
  text: string;
}

export const AdvantagesOfBringingYourSalaryToInter = () => {
  const cards: ICard[] = [
    {
      iconCategory: 'finance',
      icon: 'ic_coin_arrow_up_out',
      text: 'Conta e cartão gratuitos ',
    },
    {
      iconCategory: 'brands',
      icon: 'ic_inter_loop',
      text: 'Programa de pontos no cartão de crédito',
    },
    {
      iconCategory: 'human',
      icon: 'ic_hand_money',
      text: 'Plataforma de Investimentos',
    },
    {
      iconCategory: 'shopping',
      icon: 'ic_shopping_cart',
      text: 'Shopping com cashback',
    },
    {
      iconCategory: 'finance',
      icon: 'ic_credit_card',
      text: 'Conta internacional com cartão de débito físico e virtual',
    },
    {
      iconCategory: 'files',
      icon: 'ic_document',
      text: 'Empréstimos e financiamentos',
    },
    {
      iconCategory: 'shopping',
      icon: 'ic_gift',
      text: 'Viagens, Gift Cards, Seguros, Recarga e mais!',
    },
  ]
  return (
    <S.AdvantagesOfBringingYourSalaryToInterSection className='default-padding d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-lg-center'>
          <div className='col-12 col-lg-5 col-xl-6'>
            <h2 className='fs-24 lh-28 fs-md-30 lh-md-36 fs-xl-40 lh-xl-48 fw-700 text-grayscale--500 font-citrina'>Vantagens de trazer seu <span className='d-xl-block'>salário para o Inter</span></h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 fw-400 text-grayscale--500 font-inter mb-4 mb-md-5 mb-lg-0'>Trazendo seu salário pro Inter, você pode aproveitar ainda mais um Super App completo, com soluções para todos os momentos. </p>
          </div>
          <div className='col-12 col-lg-7 col-xl-6'>
            <div className='advantage-card-container'>
              {cards.map((card: ICard) => (
                <div key={card.icon} className='advantage-card d-flex align-items-center mb-4 mb-md-0'>
                  <div className='d-flex mr-3'>
                    <IconsSwitch icon={card.icon} lib='interco' customPath={`${card.iconCategory}/`} size='LG' color={orange.extra} />
                  </div>
                  <h3 className='fs-12 lh-15 fw-700 mb-0 font-inter'>{card.text}</h3>
                </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </S.AdvantagesOfBringingYourSalaryToInterSection>
  )
}

export default AdvantagesOfBringingYourSalaryToInter
