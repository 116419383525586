
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const CardWrapper = styled.a`
  cursor: pointer;
  display: block;
  margin-bottom: 24px;
  background-color: #fff;
  
  @media (min-width: ${breakpoints.md}) {
    min-height: 549px;
  }
`

export const CategoryLabel = styled.span`
  background-color: ${grayscale[100]};
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  color: ${grayscale[500]};
  width: auto;
`

export const SubCategoryLabel = styled.span`
  background-color: ${grayscale[100]};
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  font-size: 12px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 8px;
  }
`

export const CategoriesHeader = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 16px 16px 0 0;
  top: -25px;
`

export const ImageWrapper = styled.div`
  position: relative;
  width: auto;
  height: 100%;
  display: block;
  min-height: 244px;

  img {
    object-fit: cover;
    
    @media (min-width: ${breakpoints.md}) {
      position: absolute;
    }
  }
`

export const TitleWrapper = styled.div`
  padding: 0 24px;
  top: -25px;
  position: relative;
`

export const TextWrapper = styled(TitleWrapper)`
  top: -25px;
  position: relative;
`
