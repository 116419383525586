import React from 'react'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Link from '../../_componentes/_link/_index'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { ImgWebp } from 'src/styles/imgWebp'

import images, { IWebpImages } from 'src/pages/pra-voce/portabilidade-de-salario/assets/images/_images'

import * as S from './style'

export const PlatinumCardPointsPersonalizedService = () => {
  const { platinumCard }: IWebpImages = images

  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.PlatinumCardPointsPersonalizedServiceSection className='default-padding d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-4 col-xl-5 offset-xl-1 mb-4 mb-md-0 order-md-last'>
            <ImgWebp src={platinumCard.sm} alt='composição de imagens com o cartão platinum Inter no centro' breakPointsSize={{ sm: '320px', md: '300px', lg: '300px', xl: '456px' }} />
          </div>
          <div className='col-12 col-md-6 col-lg-8 col-xl-6 pr-md-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-3 font-citrina'>Cartão Platinum com programa de pontos e atendimento personalizado</h2>
            <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-25 mb-4 text-grayscale--400 fw-lg-600'>Fazendo a portabilidade do seu salário a partir de R$6 mil líquido, você se torna um cliente Inter One!</h3>
            <div className='d-flex align-items-start mb-4'>
              <div className='d-flex mr-3'>
                <OrangeDsIcon icon='check-circle' size='MD' color='#ff7a00' />
              </div>
              <p className='fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--400 mb-0'>Inter Loop: ganhe <span className='fw-700'>1 ponto a cada R$5,00</span> gastos no cartão de crédito</p>
            </div>
            <div className='d-flex align-items-start'>
              <div className='d-flex mr-3'>
                <OrangeDsIcon icon='check-circle' size='MD' color='#ff7a00' />
              </div>
              <p className='fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--400 mb-0'>Conte com um advisor para te ajudar em qualquer assunto relacionado ao Super App como empréstimos, compras, seguros ou até para montar uma carteira de investimentos de acordo com o seu momento de vida.</p>
            </div>
            <Link
              href='https://inter.co/pra-voce/relacionamento/inter-one/'
              text='Saiba mais sobre Inter One'
              className='mt-5'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  section_name: 'Cartão Platinum com mais cashback e atendimento personalizado',
                  element_action: 'click button',
                  element_name: 'Saiba mais sobre Inter One',
                  redirect_url: '/pra-voce/relacionamento/inter-one/',
                 })
              }}
            />
          </div>
        </div>
      </div>
    </S.PlatinumCardPointsPersonalizedServiceSection>
  )
}

export default PlatinumCardPointsPersonalizedService
