import React, { createContext } from "react"
import { ICarouselRef, ICompoundCarouselContext, ICompoundCarouselProps } from "./types"

export const CompoundCarouselContext = createContext<ICompoundCarouselContext | undefined>(undefined)

export const useCompoundCarouselContext = () => {
  const context = React.useContext(CompoundCarouselContext)
  if (!context) {
    throw new Error('useCompoundCarouselContext must be used within a CompoundCarouselContext')
  }
  return context
}

const CompoundCarouselProvider = ({ carouselContent, children }: ICompoundCarouselProps) => {
  const [ currentSlide, setCurrentSlide ] = React.useState<number>(1)

  const textContentCarouselRef = React.useRef<ICarouselRef | null>(null)
  const imageCarouselRef = React.useRef<ICarouselRef | null>(null)

  const applyOpacityToFirstActiveItem = () => {
    const track = document.querySelector('.react-multi-carousel-track')
    if (track) {
      const activeItems = track.querySelectorAll('.react-multi-carousel-item--active')
      if (activeItems.length > 0) {
        activeItems.forEach((item: Element, index: number) => {
          if (index === 0) {
            item.children[0].classList.add('active-image')
          } else {
            item.children[0].classList.remove('active-image')
          }
        })
      }
    }
  }
  return (
    <CompoundCarouselContext.Provider value={{ applyOpacityToFirstActiveItem, imageCarouselRef, textContentCarouselRef, setCurrentSlide, currentSlide, carouselContent }}>
      {children}
    </CompoundCarouselContext.Provider>
  )
}

export default CompoundCarouselProvider
