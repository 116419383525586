import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const RelatedContentSection = styled.section`
  .blog-content {
    display: flex;
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.extra};
    }
  }

  .card_wrapper {
    @media ${device.tablet} {
      min-height: 440px;
    }
  }
`
